import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { CurrencyField, FileUploadField } from '@jutro/legacy/components';
import {
    Button
} from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import {
    WniDateUtil,
    DocumentsUtil
} from 'wni-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {  useWniModal } from 'wni-components-platform-react';
import ManualLossComponent from "wni-capability-gateway-react/components/ManualLossComponent/ManualLossComponent";
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    WniSubmissionService
} from 'wni-capability-gateway';
import WizardPage from '../../templates/CPPWizardPage';
import CPPRiskAnalysisPage from './CPPRiskAnalysisPage';

function CPPRiskAnalysisReadOnlyPage(props) {
    return <CPPRiskAnalysisPage {...props} isReadOnly />
}


export default CPPRiskAnalysisReadOnlyPage;