import React, { Component, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
// import UWUtil from 'gw-capability-gateway-react/Components/UnderwritingComponent/UWUtil';
import { UWUtil } from 'gw-capability-gateway-react-ext';
import { useTranslator  } from '@jutro/locale';
import { JobService } from 'gw-capability-gateway';
import { WniPolicyService, WniSubmissionService } from 'wni-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import styles from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.module.scss';
import messages from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.messages';
import cx from 'classnames';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { SuccessNotification, Link as LinkComponent } from 'gw-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ErrorsAndWarningsUtil, DocumentsUtil, SummaryUtil, WniProductsUtil, WniGoLiveUtil } from 'wni-portals-util-js';
import { UnderIssuesTableComponent, DocumentCheckedComponent } from 'wni-capability-gateway-react';
import { ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { useBusinessData, useProductsData } from 'wni-portals-util-react';
import { BRCLMessages } from 'wni-platform-translations';

import { Link as GWLink } from '@jutro/router';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import metadata from './QuoteDetailsSummary.metadata.json5';
import localMessages from './QuoteDetailsSummary.messages';
import clMessages from './QuoteDetailsSummary.cl.messages';
import interalStyles from './QuoteDetailsSummary.module.scss';
import BackLinkComponent from '../../Components/BackLinkComponent/BackLinkComponent';


function DownloadDocumentComponent(props) {
    const { documents, docType } = props;

    const downloadDocument = (sessionID, publicID) => {
        // const { authHeader, history } = props;
        const serviceUrl = getProxiedServiceUrl('downloadDocument');
        const templateDownloadEndpointURL = serviceUrl.concat(
            `/${publicID}?token=${sessionID}`
        );
        const params = {
            method: 'GET',
            url: templateDownloadEndpointURL,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        };

        const errorCallback = () => {
            // history.push('/documenterror');
        };

        DocumentDownloadService.getDocument(
            templateDownloadEndpointURL,
            params,
            errorCallback
        );
    };
    if (documents == null) {
        return null;
    }
    return documents.map((doc) => {
        if (docType === doc.docType_Ext) {
            const { name, publicID, sessionID } = doc;
            return (
                <div className={cx(interalStyles['new-line'])}>
                    <GWLink
                        className={cx(interalStyles['download-link'])}
                        onClick={() => {
                            downloadDocument(sessionID, publicID);
                        }}
                        id={publicID}
                    >
                        {name}
                    </GWLink>
                </div>
            );
        }
        return null;
    });
}

const { 
    PA_PRODUCT_CODE,
    HOP_PRODUCT_CODE,
    HO_PRODUCT_CODE,
    DP_PRODUCT_CODE,
    WAL_PRODUCT_CODE,
    RT_PRODUCT_CODE,
    PU_PRODUCT_CODE,
    CA_PRODUCT_CODE,
    WCM_PRODUCT_CODE,
    GL_PRODUCT_CODE,
    CP_PRODUCT_CODE
} = WniProductsUtil;
function QuoteDetailsSummary(props) {
    const modalApi = useWniModal();
    const {
        fromAccountLanding: {
            quoteDetailsData: {
                loadQuoteSummary: submission,
                updateJobSummary: customUpdateJobSummary,
                AOLink
            },
        },
        history,
        // authHeader,
    } = props;
    const {
        jobNumber,
        productCode: policyProduct,
        status: submissionStatus,
        policy: {
            policyState_Ext: policyState
        },
        currentVersionLocked_Ext: currentVersionLocked,
        reQuotedByAMR_Ext: reQuotedByAMR,
        createUser_Ext: {
            userType: createUserType
        } = {},
        type,
    } = submission;
    const { lobQuoteURL } = appConfig;

    const isCL = WniProductsUtil.isCLProduct(policyProduct);
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const { enableCommercialLine, getFeatureCodeValue } = useBusinessData();
    const { getProductItem } = useProductsData();

    const {
        interactionModel,
        domainCompany
    } = useDependencies(['interactionModel', 'domainCompany', 'loadingMask']);

    const [canWithdraw, updateCanWithdraw] = useState(false);
    const [isLoading, updateIsLoading] = useState(true);
    // const [isCopying, updateIsCopying] = useState(false);
    const [documentsData, updateDocumentsData] = useState([]);
    const [isShowNotification, updateIsShowNotification] = useState(false);
    const [referredToUnderWriter, updateReferredToUnderWriter] = useState(false);
    const [showReferToUW, updateShowReferToUW] = useState(false);
    const [newSubmissionObj, udpateNewSubmissionObj] = useState({});
    const [retainInAgency, udpateRetainInAgency] = useState([]);
    const [submitToWesternNational, updateSubmitToWesternNational] = useState(undefined);
    // const { interactionModel } = useDependencies('interactionModel');
    const [isServiceInProgress, updateIsServiceInProgress] = useState(false);
    const [dynamicNotifications, updateDynamicNotifications] = useState([]);

    const enablePOI53227GoLiveFeatureForPE = getFeatureCodeValue('EnablePOI53227GoLiveFeatureForPE');
    const enableProducerEngageWithAgentsOnline = getFeatureCodeValue('EnableProducerEngageWithAgentsOnline')

    const setShowReferToUW = (newShowReferToUW) => {
        updateShowReferToUW(newShowReferToUW);
    };

    const checkReferredToUnderWriter = async () => {
        const referStatus = await WniPolicyService.referredToUnderWriter(
            [jobNumber],
            authHeader
        );
        updateReferredToUnderWriter(referStatus);
    };

    const handleError = (title, message) => {
        return modalApi.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        });
    };

    const getDocuments = async () => {
        try {
            let getAvailableDocumentsData = null;
            const {
                productCode,
                // policy: {
                //     issued
                // } = {}
            } = submission;
            const isCLProduct = WniProductsUtil.isCLProduct(policyProduct);
            if (submission.policy !== null && submission.policy.issued) {
                const policyNumber = _.get(submission, 'policyNumber_Ext');
                getAvailableDocumentsData = isCLProduct
                    ? await WniPolicyService.getPortalCLDocumentsForPolicyGroupByType(
                          policyNumber,
                          authHeader
                      )
                    : await WniPolicyService.getPortalDocumentsForPolicyGroupByType(
                          policyNumber,
                          authHeader
                      );
            } else {
                getAvailableDocumentsData = isCLProduct
                    ? await WniSubmissionService.getPortalCLDocumentsForSubmissionGroupByType(
                          [jobNumber],
                          authHeader
                      )
                    : await WniSubmissionService.getPortalDocumentsForSubmissionGroupByType(
                          [jobNumber],
                          authHeader
                      );
            }
            const retainInAgencyDocs = DocumentsUtil.getDocumentDataByVersion(getAvailableDocumentsData,
                DocumentsUtil.RETAIN_IN_AGENCY, _.get(submission, 'selectedVersion_Ext'));
            const submitToWesternNationalDocs = DocumentsUtil.getDocumentData(
                getAvailableDocumentsData,
                DocumentsUtil.SUBMIT_TO_WESTERNNATIONAL
            );
            updateDocumentsData(getAvailableDocumentsData);
            udpateRetainInAgency(retainInAgencyDocs);
            updateSubmitToWesternNational(submitToWesternNationalDocs);
        } catch (e) {
            handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    };

    const checkWithdrawStatus = () => {
        if (!_.isEmpty(submission)) {
            // usage: translator({ id: 'typekey.PolicyPeriodStatus.Bound' })

            if (submissionStatus === 'Not-taken') {
                updateCanWithdraw(false);
            } else if (
                submissionStatus !== 'Bound'
                && !submission.policy.issued
                && !submission.closeDate
            ) {
                updateCanWithdraw(true);
            }
        }
    };

    useEffect(() => {

        checkWithdrawStatus();
        Promise.all([
            getDocuments(),
            checkReferredToUnderWriter()
        ]).then(() => {
            updateIsLoading(false)
        })
        
        // POI-4182: another solution is to introduce another field to do it -- which is not that much fun.
        // Mec on 2023-11-27 (Mon): There are currently following fields capable of
        // showing messages: notification, dynamicInlineNotificationContainer, quoteNotification, underwritingTable, etc.
        // This is a total mess, and should be cleaned. 
        if (reQuotedByAMR) {
            updateDynamicNotifications([{
                reason: translator(BRCLMessages.BR_POI_4182),
                type: 'warning',
            }]);
        }
    }, []);

    const onContinueTransaction = ({ isReadOnly = false }) => {
        const productCode = _.get(submission, 'productCode');
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (submission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    isReadOnly,
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: submission.jobNumber,
                        // producerCode_Ext: submission.producerCode_Ext
                    }
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(submission.jobNumber);
            }
        }
    };

    const onCopySubmission = async () => {
        // updateIsCopying(true);
        updateIsServiceInProgress('copySubmission');
        const newSubmission = await WniSubmissionService.copySubmission(
            submission.jobNumber,
            authHeader
        );
        const productCode = _.get(newSubmission, 'productCode');
        if (newSubmission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                updateIsShowNotification(true);
                udpateNewSubmissionObj(newSubmission);
            }
        }
        // updateIsCopying(false);
        updateIsServiceInProgress(false);
    };
    
    const onCopyToSubmissionByWay = async (targetProductCode) => {

        const srcProductShortName = WniProductsUtil.getProductShortName(policyProduct)
        const targetProductShortName = WniProductsUtil.getProductShortName(targetProductCode)
        updateIsServiceInProgress(`copy${srcProductShortName}SubmissionTo${targetProductShortName}`);
        const way = `${srcProductShortName}To${targetProductShortName}`
        const newSubmission = await WniSubmissionService.copySubmissionByWay(
            submission.jobNumber,
            way,
            authHeader
        );
        const productCode = _.get(newSubmission, 'productCode');
        const postalCode = _.get(
            newSubmission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (newSubmission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                await WniSubmissionService.addRecentlyViewedSubmission(newSubmission.jobNumber, authHeader);
                const nextLocation = {
                    isReadOnly: false,
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: newSubmission.jobNumber,
                    }
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(newSubmission.jobNumber);
            }
        }
        updateIsServiceInProgress(false);
    };

    const onCopyToHOSubmission = async () => {
        await onCopyToSubmissionByWay(HOP_PRODUCT_CODE)
    };

    const onCopyToDPSubmission = async () => {
        await onCopyToSubmissionByWay(DP_PRODUCT_CODE)
    }

    const goToNextSubmission = async (historyParam, lobQuoteURLParam, productCode, nextLocation) => {
        await WniSubmissionService.addRecentlyViewedSubmission(newSubmissionObj.jobNumber, authHeader);
        historyParam.push(lobQuoteURLParam[productCode], nextLocation);
    };

    const getCopyMsg = (newSubmissionObjParam, isShowNotificationParam) => {
        if (!_.isEmpty(newSubmissionObjParam) && isShowNotificationParam) {
            const productCode = _.get(newSubmissionObjParam, 'productCode');
            const postalCode = _.get(
                newSubmissionObjParam,
                'policy.account.accountHolder.primaryAddress.postalCode'
            );
            const nextJobNum = newSubmissionObjParam.jobNumber;
            const nextLocation = {
                quoteentry: {
                    postalCode: postalCode,
                    quoteID: nextJobNum,
                },
            };
            // return (
            //     <div>
            //         The application has been copied to &nbsp;
            //         <GWLink
            //             onClick={() => goToNextSubmission(history, lobQuoteURL, productCode, nextLocation)}
            //             className="font-bold"
            //         >
            //             {nextJobNum}
            //         </GWLink>
            //     </div>
            // );
            return {
                nextJobNum,
                history,
                lobQuoteURL,
                productCode,
                nextLocation
            }
        }
    };

    const updateJobSummary = (submissionParam) => {
        if (customUpdateJobSummary) {
            customUpdateJobSummary(submissionParam);
        }
    };

    const updateWithDrawQuote = (jobStatusCode, submissionParam) => {
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode,
        });
        const newQuoteData = _.clone(submissionParam);
        _.set(newQuoteData, 'status', status);
        _.set(newQuoteData, 'statusCode', jobStatusCode);
        updateJobSummary(newQuoteData);
    };

    const onWithdrawTransaction = () => {
        modalApi.showConfirm({
            title: messages.withdrawJob,
            message: translator(localMessages.sureWithDrawQuote,
                { quoteNumber: submission.jobNumber }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            updateIsServiceInProgress('referReviewWithDrawJob');
            JobService.withdrawJobByJobNumber(
                submission.jobNumber,
                authHeader
            ).then(
                () => {
                    updateWithDrawQuote('Withdrawn', submission);
                    updateIsServiceInProgress(false);
                },
                () => {
                    updateIsServiceInProgress(false);
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    });
                }
            );
        }).catch(_.noop);
    };

    const onViewQuote = () => {
        onContinueTransaction({ isReadOnly: true });
    };

    const hasUwIssues = (uwIssues) => {
        return uwIssues && uwIssues.length > 0;
    };

    const getIsViewTransaction = () => {
        return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
    };

    const getSubmissionToProceed = () => {
        // update available based on go live config
        const isGolivePass = WniGoLiveUtil.checkProductGoliveAvailable(_.get(submission, 'productCode'), enableCommercialLine, getProductItem);

        if ((isCL && !enableCommercialLine) || !isGolivePass) {
            return {
                isCopyToHOSubmission: false,
                isCopyToDPSubmission: false,
                isCopySubmission: false,
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: false,
                isReferToUnderWriterTransaction: false
            };
        }
        const hasCreateSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.createsubmission');
        const hasEditSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.editsubmission');
        const hasWithDrawPermission = _.get(authUserData, 'permissionMap_Ext.withdraw');
        const hasCopyToHOSubmission = [PA_PRODUCT_CODE, DP_PRODUCT_CODE].includes(policyProduct)
            && !['Draft', 'Withdrawn'].includes(submission.statusCode);
        const hasCopyToDPSubmission = [PA_PRODUCT_CODE, HOP_PRODUCT_CODE].includes(policyProduct)
            && !['Draft', 'Withdrawn'].includes(submission.statusCode);
        if (
            submission && (submission.statusCode === 'Withdrawn')
        ) {
            return {
                isCopySubmission: hasCreateSubmissionPermission,
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: getIsViewTransaction() && !_.get(authUserData, 'isExternalUser_Ext')
            };
        }
        return {
            isCopyToHOSubmission: hasCopyToHOSubmission,
            isCopyToDPSubmission: hasCopyToDPSubmission,
            isCopySubmission: hasCreateSubmissionPermission && 
                (!WniProductsUtil.isCLProduct(policyProduct) || !currentVersionLocked),
            isContinueTransaction:
                hasEditSubmissionPermission && canWithdraw
                && !submission.currentVersionLocked_Ext,
            isWithdrawTransaction: canWithdraw && hasWithDrawPermission,
            isViewTransaction: getIsViewTransaction() && !_.get(authUserData, 'isExternalUser_Ext'),
            // isReferToUnderWriterTransaction: hasReferToUnderWriterPermission || _.get(authUserData, 'isExternalUser_Ext')
            isReferToUnderWriterTransaction: false
        };
    };

    const getUWIssuesApprovedNotificationContent = () => {
        const transactionVisibleActions = getSubmissionToProceed();
        const uwutil = UWUtil(submission, authUserData.userType);
        return {
            infoMessageTitle: WniProductsUtil.isCLProduct(policyProduct) ?
                translator(clMessages[`clApprovedForThis${uwutil.jobType}`]) :
                translator(localMessages[`approvedForThis${uwutil.jobType}`]),
        };
    };

    const uwutil = UWUtil(submission, authUserData.userType); 

    const getNotificationContent = (warningMessagesAndSuccessNotificationVisibility) => {
        const {
            successNotification: {
                showUWIssuesApprovedMsg
            }
        } = warningMessagesAndSuccessNotificationVisibility;
        const selectedVersionPublicID = _.get(submission, 'selectedVersion_Ext');
        const underwritingIssues = _.get(submission, 'underwritingIssues');
        const callingFromAPI = _.get(submission, 'callingFromAPI_Ext');
        let hasBlockingUWIssue = ErrorsAndWarningsUtil
            .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);
            
        const content = {};
        const isCLProduct = WniProductsUtil.isCLProduct(policyProduct);
        if (isCLProduct) {
            hasBlockingUWIssue = hasBlockingUWIssue || uwutil.uwIssuesHaveBeenRejectedByUW;
        }
        const withdrawButtonContent = isCLProduct ? translator(clMessages.clWithdrawQuote) : translator(localMessages.deleteQuote);
        if (
            submission
                && !['Bound', 'Quoted', 'Withdrawn'].includes(submission.statusCode)
                && ((['Draft'].includes(submission.statusCode) && !submission.currentVersionLocked_Ext) || !hasUwIssues(submission.underwritingIssues))
        ) {
            content.infoMessageTitle = isCLProduct ? 
            translator(
                clMessages.clDraftInfoMessage
            ) : translator(
                messages.quoteSuccessfullyStarted
            );
        }
        if (
            submission
            && (['Quoted', 'Rated', 'Approved_Ext'].includes(submission.statusCode))
            && (!hasBlockingUWIssue || (hasBlockingUWIssue && callingFromAPI))
        ) {
            content.infoMessageTitle = isCLProduct ?
                translator(
                    clMessages.clApprovedForThisSubmission
                ) :
                translator(
                    messages.premiumQuoteCalculated
                );
        }
        if (submission && submission.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(
                messages.thisQuoteHasBeenWithDrawn
            );
        }
        if (submission && submission.statusCode === 'Bound') {
            content.infoMessageTitle = translator(localMessages.thePolicyIsIssued);
        }
        let infoMessages = [{
            infoMessageTitle: content.infoMessageTitle,
        }];

        if (showUWIssuesApprovedMsg && !isCLProduct) {
            infoMessages = infoMessages.concat(getUWIssuesApprovedNotificationContent());
        }

        return {
            infoMessages: infoMessages,
            withDrawContent: withdrawButtonContent,
            continueContent: translator(messages.continueQuote),
            viewContent: translator(localMessages.viewQuote),
            copyContent: translator(localMessages.copySubmission),
            copyToHOContent: translator(localMessages.copyToHOQuote),
            copyToDPContent: translator(localMessages.copyToDPQuote)
        };
    };

    const getPolicyLinkVisible = () => {
        const policyNumberAssigned = _.get(
            submission,
            'policyNumberAssigned_Ext'
        );
        return policyNumberAssigned;
    };

    const redirectToPolicy = () => {
        const policyNumber = _.get(submission, 'policyNumber_Ext');
        const accountNumber = _.get(submission, 'policy.account.accountNumber');
        const accountType = _.get(submission, 'policy.account.accountHolder.contactType_Ext');
        const url = interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber);
        return (
            <LinkComponent
                {...url}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </LinkComponent>
        );
    };

    const getJobStatus = (job) => {
        if (job.policy) {
            if (job.policy.issued) {
                return translator(gatewayMessages.issued);
            }
            if (job.status === 'Not-taken') {
                return translator(gatewayMessages.notTaken);
            }
        }
        return job.status;
    };

    // const goToAllQuotes = () => {
    //     history.push({
    //         pathname: '/policies',
    //         state: {
    //             initStatus: 'Quotes',
    //         },
    //     });
    // };

    const downloadDocumentAll = async () => {
        // documentsData.map((doc) => {
        documentsData.forEach((doc) => {
            const { publicID, sessionID } = doc;

            const serviceUrl = getProxiedServiceUrl('downloadDocument');
            const templateDownloadEndpointURL = serviceUrl.concat(
                `/${publicID}?token=${sessionID}`
            );
            const params = {
                method: 'GET',
                url: templateDownloadEndpointURL,
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'arraybuffer',
            };

            const errorCallback = () => {
                history.push('/documenterror');
            };

            DocumentDownloadService.getDocument(
                templateDownloadEndpointURL,
                params,
                errorCallback
            );
        });
    };

    const selectedVersionPublicID = _.get(submission, 'selectedVersion_Ext');
    const selectedPolicyPeriod = _.get(submission, 'policy.periods', [])
    .find((policyPeriod) => policyPeriod.publicID === selectedVersionPublicID);

    const getStatusInfo = () => {
        const subStatus = getJobStatus(submission);
        if (
            subStatus.toLowerCase() === 'bound'
                && submission.policy.issued
        ) {
            return translator('gateway.directives.JobSummary.Issued');
        }
        return subStatus;
    };

    const getSummaryDetailGridRow = () => {
        if (isCL) {
            return ['1fr', '1fr', '1fr', '1fr']
        };
        return submission.statusCode === 'Quoted' || submission.statusCode === 'Bound' || submission.statusCode === 'Rated'
                ? ['1fr', '1fr', '1fr', '1fr'] : ['1fr', '1fr', '1fr'];
    }

    const getTotalPremiumVisiblity = () => {
        if (isCL) { 
            return true;
        }
        return submission.statusCode === 'Quoted' || submission.statusCode === 'Bound' || submission.statusCode === 'Rated';
    }

    const getTotalPremiumValue = () => { 
        const isShowTotalPremiumForCL = ((!currentVersionLocked && !SummaryUtil.isLockedDueToCreatedbyUW(type, createUserType,authUserData.userType))
            && (_.includes(['Quoted', 'Approved', 'Bound'], submissionStatus)));
        const totalPremiumValue = _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext.amount') > 0 ? _.get(selectedPolicyPeriod, 'totalPremium') : _.get(selectedPolicyPeriod, 'totalCost');
        if (isCL) {
            if (isShowTotalPremiumForCL){
                return totalPremiumValue;
            } 
            return undefined;
        } 
        return totalPremiumValue;
    };

    // =======================================================================
    if (_.isEmpty(submission) || isLoading) {
        return <Loader loaded={isLoading} />;
    }
    const producerCodeOfServiceDescription = _.get(submission, 'producerCodeOfService_Ext.description');
    const producerCodeOfRecordDescription = _.get(submission, 'producerCodeOfRecord_Ext.description');

    const underwritingIssues = _.get(submission, 'underwritingIssues');
    const hasBlockingUWIssue = ErrorsAndWarningsUtil.quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues,
        selectedVersionPublicID);

    const documentEmpty = (retainInAgency && _.get(retainInAgency, 'length') > 0)
        || (submitToWesternNational && _.get(submitToWesternNational, 'length') > 0);
    

    const warningMessagesAndSuccessNotificationVisibility = SummaryUtil.getWarningMessagesAndSuccessNotificationVisibility(
        submission,
        authUserData,
        referredToUnderWriter,
        showReferToUW,
        UWUtil,
        getSubmissionToProceed()
    );

    // ============================================================================
    const accountNumber = _.get(submission, 'policy.account.accountNumber');
    const accountType = _.get(submission, 'policy.account.accountHolder.contactType_Ext');
    const copyMsg = getCopyMsg(newSubmissionObj, isShowNotification);
    const overrides = {
        notification: {
            message: `The application has been copied to `,
            linkProps: {
                children: _.get(copyMsg, 'nextJobNum'),
                onClick: () => goToNextSubmission(
                    _.get(copyMsg, 'history'),
                    _.get(copyMsg, 'lobQuoteURL'),
                    _.get(copyMsg, 'productCode'),
                    _.get(copyMsg, 'nextLocation')
                )
            },
            visible: isShowNotification
        },
        quoteNotification: {
            notificationContent: getNotificationContent(warningMessagesAndSuccessNotificationVisibility),
            transactionVisibleActions: getSubmissionToProceed(),
            visible: !warningMessagesAndSuccessNotificationVisibility.warningExist,
            isCopying: isServiceInProgress,
            baseState: policyState
        },
        underwritingTable: {
            job: submission,
            jobService: WniSubmissionService,
            onUpdateJobSummary: updateJobSummary,
            transactionVisibleActions: getSubmissionToProceed(),
            referredToUnderWriter: referredToUnderWriter,
            checkReferredToUnderWriter: checkReferredToUnderWriter,
            hasBlockingUWIssue: hasBlockingUWIssue,
            showReferToUW: showReferToUW,
            setShowReferToUW: setShowReferToUW,
            warningMessagesAndSuccessNotificationVisibility:
                warningMessagesAndSuccessNotificationVisibility,
            onCopyToHOSubmission: onCopyToHOSubmission,
            onCopyToDPSubmission: onCopyToDPSubmission,
            baseState: policyState
        },
        underwritingIssues: {
            uwIssues: submission.underwritingIssues.filter((issue) => issue.issueTypeCode_Ext !== 'HOPCheckIssuanceForVirtualInspection_Ext'),
            uwUtil: uwutil,
            noRecordsMessage: translator(messages[`noOpenUnderwriting${uwutil.jobType}`]),
            visible: !['Bound', 'Withdrawn'].includes(submission.statusCode)
                && hasUwIssues(submission.underwritingIssues)
                && !WniProductsUtil.isCLProduct(policyProduct),
        },
        summaryDetailGridRow: {
            columns: getSummaryDetailGridRow()
        },
        policyInfoLink: {
            visible: getPolicyLinkVisible(),
            value: redirectToPolicy(),
        },
        detailGridTotalColumn: {
            visible: getTotalPremiumVisiblity()
        },
        totalPremiumId: {
            value: getTotalPremiumValue()
        },
        fullPayDiscountId: {
            visible: _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay' && _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext.amount') > 0,
            value: _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext')
        },
        totalCostId: {
            value: _.get(selectedPolicyPeriod, 'totalCost'),
            visible: submission.statusCode !== 'Rated' && _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay' && _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext.amount') > 0
        },
        agencyOfRecordOrgId: {
            value: `${producerCodeOfRecordDescription}`,
        },
        agencyOfServiceId: {
            value: `${producerCodeOfServiceDescription}`,
        },
        summaryCreatedDataId: {
            value: _.get(submission, 'createTime')
        },
        summaryPolicyInceptionId: {
            value: _.get(submission, 'effectiveDate_Ext')
        },
        summaryPolicyExpirationDateId: {
            value: _.get(submission, 'policyExpirationDate_Ext')
        },
        summaryStatusdDataId: {
            value: getStatusInfo(),
        },
        dynamicInlineNotificationContainer: {
            validationIssues: dynamicNotifications,
            visible: dynamicNotifications.length > 0,
            scrollToIssues: true,
        },
        documentcheckedList: {
            visible: !enableCommercialLine ? documentEmpty : (documentEmpty && ['Approved', 'Bound'].includes(submissionStatus)),
            history: history,
            submission,
            renderData: [
                {
                    subTitle: {
                        id: 'quoteandbind.pa.views.pa-policy-info.Retain in Agency',
                        defaultMessage: 'Retain in Agency',
                    },
                    data: retainInAgency
                },
                {
                    subTitle: translator(localMessages.WesternNational, { domainName: `${domainCompany.domainName}` }),
                    data: submitToWesternNational
                }
            ],
            showSendViaEmail: true,
            forceDocumentColumnName: !enableCommercialLine ? undefined : 'simpleName_Ext'
        },
        jumpToAOPaymentContainer: {
            visible: _.get(AOLink, 'payStatus') === 'cancel'
        },
        paymentNotSubmitted: {
            className: "font-bold"
        },
        paymentAOLink: {
            ...interactionModel.getURLObj(accountType, 'accountBilling', accountNumber)
        },
        backToPolicyDetailsLink: {
            visible: _.get(submission, 'statusCode') === 'Bound',
            content: enableProducerEngageWithAgentsOnline ? localMessages.backTOPolicyDetails : localMessages.backTOPolicySummary,
            ...interactionModel.getURLObj(accountType, 'policySummary', accountNumber, jobNumber)
        },

        backLinkContainer: {
            accountNumber,
            accountType,
            history: history,
            showBackToAccountSummaryLink: true
        }
    };
    const resolvers = {
        resolveClassNameMap: interalStyles,
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            notificationcomponent: SuccessNotification,
            underwritingcomponent: UnderwritingComponent,
            uwissuestablecomponent: UnderIssuesTableComponent,
            downloaddocumentcomponent: DownloadDocumentComponent,
            documentcheckedcomponent: DocumentCheckedComponent,
            backlinkcomponent: BackLinkComponent
        },
        resolveCallbackMap: {
            onCopySubmission: onCopySubmission,
            onCopyToHOSubmission: onCopyToHOSubmission,
            onCopyToDPSubmission: onCopyToDPSubmission,
            onContinueTransaction: onContinueTransaction,
            onWithdrawTransaction: onWithdrawTransaction,
            onViewTransaction: onViewQuote,
            redirectToPolicy: redirectToPolicy,
            downloadDocumentAll: downloadDocumentAll,
            // goToAllQuotes: goToAllQuotes,
        },
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            submission,
            id,
            path,
            overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submission}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

QuoteDetailsSummary.propTypes = {
    fromAccountLanding: PropTypes.shape({
        quoteDetailsData: PropTypes.shape({
            jobNumber: PropTypes.string.isRequired,
            loadQuoteSummary: PropTypes.shape({}).isRequired,
            updateJobSummary: PropTypes.func.isRequired,
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

// export default withAuthenticationContext(withDependencies(['domainCompany'])(QuoteDetailsSummary));
export default QuoteDetailsSummary;
