
import React, {
    useCallback, useMemo, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, getFlattenedUiPropsContent, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { IssuanceValidationUtil } from 'wni-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { useWniModal } from 'wni-components-platform-react'
import AnimalsPopupComponent from '../AnimalsPopupComponent/AnimalsPopupComponent';
import defaultMetadata from './LiabilityExposuresComponent.metadata.json5';
import { Button } from '@jutro/legacy/components';

const LiabilityExposuresComponent = (props) => {
    const modalApi = useWniModal();
    const {
        model: liabilityExposuresVM,
        isReadOnly,
        onValidate,
        showErrors,
        baseState,
        onValueChange,
        isRCTSuccess,
        RCTSubmission,
        coverageForm,
        isRequiredForIssuance,
        animalsVM,
        editAnimal,
        removeAnimals,
        messages,
        isFeb19EntityChangeVer,
        isSubmissionType,
        DPOverrideProps,
        metadataOverride,
        linePath
    } = props;

    const metadata = metadataOverride || defaultMetadata;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        swimmingPoolType,
        approvedFence,
        divingBoard,
        existATrampoline,
        ownAnyAnimals
    } = liabilityExposuresVM.value;

    const showAnimalModal = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: false,
            baseState,
            // actionBtnLabel: messages.ok,
            // cancelBtnLabel: messages.cancel,
            size: 'lg',
            animalsVM,
            isReadOnly,
            editAnimal,
            removeAnimals,
            viewModelService,
            isSubmissionType,
            linePath
        };
        return modalApi.showModal(
            <AnimalsPopupComponent {...componentProps} />
        );
    }, [animalsVM, editAnimal, isReadOnly]);

    const onDetailsLinkClick = useCallback(() => {
        showAnimalModal().then((res) => {
            // editAnimal(res);
        }).catch(() => {
            _.noop();
        });
    }, []);

    const ViewOrEditButtonLink = useCallback(() => {
        if (ownAnyAnimals === 'yes') {
            return <Button className="wni-button-link" onClick={onDetailsLinkClick} >
                &nbsp;&nbsp;{translator(isReadOnly ? messages.viewAnimalDetail : messages.viewOrEditAnimalDetail)}
            </Button>
        }
        return null
    }, [ownAnyAnimals]);

    useEffect(() => {
        if (swimmingPoolType === 'none_Ext') {
            onValueChange(null, 'approvedFence');
            onValueChange(null, 'divingBoard');
        }
    }, []);

    const handleSwimmingPoolType = useCallback((value, path) => {
        onValueChange(value, path);
        if (value === 'none_Ext') {
            onValueChange(null, 'approvedFence');
            onValueChange(null, 'divingBoard');
        }
    }, []);
    const flatMetadata = useMemo(() => getFlattenedUiPropsContent(metadata.componentContent), []);
    // get required for issuance Field id
    const overridesForIssuance = IssuanceValidationUtil.generateIssuanceOverrides(flatMetadata, isRequiredForIssuance);
    const overrideProps = {
        '@all': {
            readOnly: isReadOnly,
            tabIndex: -1
        },
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            isRequiredForIssuance
        },
        numberOfResidenceEmployees: {
            defaultValue: 0
        },
        ownAnyAnimals: {
            value: ownAnyAnimals,
            secondaryLabel: <ViewOrEditButtonLink />
        },
        swimmingPoolType: {
            // defaultValue: 'none_Ext',
            // readOnly: isRCTSuccess || !_.isNil(RCTSwimmingPoolType),
            visible: coverageForm !== 'ho4' && coverageForm !== 'ho6',
            onValueChange: handleSwimmingPoolType
        },
        approvedFence: {
            visible: (swimmingPoolType === 'AboveGround' || swimmingPoolType === 'InGround' || swimmingPoolType === 'Indoor_Ext')
                && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        approvedFenceExplain: {
            visible: (swimmingPoolType === 'AboveGround' || swimmingPoolType === 'InGround' || swimmingPoolType === 'Indoor_Ext')
            && approvedFence === false && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        divingBoard: {
            label: messages.divingBoard,
            visible: (swimmingPoolType === 'AboveGround'
            || swimmingPoolType === 'InGround'
            || swimmingPoolType === 'Indoor_Ext')
            && coverageForm !== 'ho4'
            && coverageForm !== 'ho6',
        },
        depthOfThePool: {
            visible: (swimmingPoolType === 'AboveGround'
            || swimmingPoolType === 'InGround'
            || swimmingPoolType === 'Indoor_Ext')
            && divingBoard === true && coverageForm !== 'ho4' && coverageForm !== 'ho6'
        },
        hotTub: {
            defaultValue: 'none'
        },
        isTrampolineNetted: {
            visible: existATrampoline === true
        },
        existATrampoline: {
            defaultValue: false
        },
        isTrampolineSecured: {
            visible: isFeb19EntityChangeVer && existATrampoline === true,
            require: isFeb19EntityChangeVer && existATrampoline === true
        }
    };
    const overrides = _.mergeWith(overrideProps, DPOverrideProps, overridesForIssuance, (obj, src) => {
        return {...obj, ...src}
    });
    const resolvers = {
        resolveCallbackMap: {
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={liabilityExposuresVM}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={onValueChange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
};

LiabilityExposuresComponent.propTypes = {
    model: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    animalsVM: PropTypes.shape(
        {
            value: PropTypes.shape({})
        }
    ),
    isReadOnly: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    baseState: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    isRCTSuccess: PropTypes.bool,
    RCTSubmission: PropTypes.shape({}),
    coverageForm: PropTypes.string,
    editAnimal: PropTypes.func,
    messages: PropTypes.object,
    isFeb19EntityChangeVer: PropTypes.bool,
    isSubmissionType: PropTypes.string,
    DPOverrideProps: PropTypes.shape({}),
};

LiabilityExposuresComponent.defaultProps = {
    model: {},
    animalsVM: {},
    showErrors: true,
    isReadOnly: false,
    baseState: '',
    onValidate: _.noop,
    isRCTSuccess: false,
    RCTSubmission: {},
    coverageForm: '',
    editAnimal: _.noop,
    messages: {},
    isFeb19EntityChangeVer: true,
    DPOverrideProps: {},
};

export default LiabilityExposuresComponent;
